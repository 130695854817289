import React, { FC } from 'react'
import { journalItems_elements_edges_node } from '../../../__generated__/journalItems'
import Button from '../../common/Button/Button'
import JournalItem from '../../journal/JournalItem/JournalItem'
import MaxWidth from '../../layout/MaxWidth/MaxWidth'
import { ButtonTheme } from '../../types'
import css from './MainJournalBlock.module.scss'
import cx from 'classnames'

const MainJournalBlock: FC<{ items: journalItems_elements_edges_node[] }> = ({
  items,
}) => {
  return (
    <section className={css.wrapper}>
      <MaxWidth mobileWide>
        <div className={css.top}>
          <h2 className={cx('apoc', css.heading)}>Журнал</h2>
          <p>новинки, обзоры и&nbsp;макияжи </p>

          <Button
            theme={ButtonTheme.ellipse}
            href="/journal"
            className={css.button}
          >
            Все статьи
          </Button>
        </div>
        <ul className={css.list}>
          {items.map((item, index) => (
            <JournalItem
              key={item.id}
              post={item}
              size="small"
              imageKind={index === 1 ? 'high' : index === 2 ? 'square' : null}
            />
          ))}
        </ul>
      </MaxWidth>
    </section>
  )
}

export default MainJournalBlock
