import React, { FC, useContext, useState } from 'react'
import MaxWidth from '../../layout/MaxWidth/MaxWidth'
import css from './MainBeforeAndAfter.module.scss'
import cx from 'classnames'
import Link from 'next/link'
import { MainPageContext } from '../../../contexts/mainPageProvider'
import Image from '../../common/Image/Image'

const MainBeforeAndAfter: FC = () => {
  const [toggle, setToggle] = useState(false)
  const { beforeAndAfter } = useContext(MainPageContext)

  if (!beforeAndAfter) {
    return null
  }

  return (
    <MaxWidth mobileWide>
      <section className={css.wrapper}>
        <div className={css.top}>
          <h2 className={css.title}>{beforeAndAfter.title}</h2>
          <p className={css.description}>{beforeAndAfter.description}</p>
        </div>

        <figure className={css.toggle_image_wrapper}>
          <span>
            {beforeAndAfter.before && (
              <Image
                src={beforeAndAfter.before}
                alt="До"
                layout="responsive"
                width={640}
                height={800}
                objectFit="cover"
                quality={90}
              />
            )}
          </span>
          <span className={toggle ? css.active : css.hidden}>
            {beforeAndAfter.after && (
              <Image
                src={beforeAndAfter.after}
                alt="После"
                layout="responsive"
                width={640}
                height={800}
                objectFit="cover"
                quality={90}
              />
            )}
          </span>

          {beforeAndAfter.after && beforeAndAfter.before && (
            <figcaption
              className={cx(css.toggleWrapper, toggle && css.active)}
              onClick={() => setToggle((prev) => !prev)}
            >
              <span className={cx(toggle && css.active)}>после</span>
              <div className={css.toggle_mini}>
                <Image
                  src={beforeAndAfter.after}
                  alt="До"
                  layout="responsive"
                  width={71}
                  height={71}
                  objectFit="cover"
                  quality={70}
                />
              </div>

              <div
                className={cx(
                  css.toggle_mini,
                  toggle ? css.active : css.hidden,
                )}
              >
                <Image
                  src={beforeAndAfter.after}
                  alt="После"
                  layout="responsive"
                  width={71}
                  height={71}
                  objectFit="cover"
                  quality={70}
                />
              </div>

              <span className={cx(!toggle && css.active)}>до</span>
            </figcaption>
          )}
        </figure>

        <Link href={beforeAndAfter.link} passHref>
          <a className={css.catalog_link}>
            <figure>
              <Image
                src={beforeAndAfter.linkImg}
                layout="responsive"
                width={555}
                height={600}
                objectFit="cover"
                quality={90}
                alt="Изображение категории 'Лицо'"
              />
              <figcaption>перейти в раздел</figcaption>
            </figure>
          </a>
        </Link>

        {beforeAndAfter.icons?.length > 0 && (
          <ul className={css.advantages_list}>
            {beforeAndAfter.icons.map((icon) => (
              <li key={icon.id}>
                {icon.img && (
                  <Image
                    src={icon.img}
                    layout="responsive"
                    width={55}
                    height={55}
                  />
                )}
                <p>{icon.descr}</p>
              </li>
            ))}
          </ul>
        )}
      </section>
    </MaxWidth>
  )
}

export default MainBeforeAndAfter
