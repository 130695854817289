import React, { FC } from 'react'
import css from './MainSlider.module.scss'
import cx from 'classnames'
import { ButtonTheme, IMainSliderSlide } from '../../types'
import Button from '../../common/Button/Button'
import Image from '../../common/Image/Image'
import Link from 'next/link'

export const MainSliderSlide: FC<{ slide: IMainSliderSlide; index: number }> =
  ({ slide, index }) => {
    const content = (
      <div className={cx(css.img, 'slide-inner')}>
        <div className={css.textOuter}>
          <div
            className={cx(css.text, css[slide.textAlign], css[slide.textColor])}
          >
            {slide.title && <h2 className={css.title}>{slide.title}</h2>}
            {slide.description && (
              <p className={css.description}>{slide.description}</p>
            )}
            {slide.buttonText && (
              <Button
                href={
                  slide.buttonLink && !slide?.slideLink
                    ? slide.buttonLink
                    : null
                }
                theme={ButtonTheme.ellipse}
                className={cx(css.button, css[slide.buttonColor])}
              >
                {slide.buttonText}
              </Button>
            )}
          </div>
        </div>

        <div className={css.picture}>
          <Image
            src={slide.img}
            layout="fill"
            objectFit="cover"
            loading={index === 0 || index === 1 ? 'eager' : 'lazy'}
            priority={index === 0 || index === 1}
            sizes="(max-width:768px) 10px, 1920px"
            quality={90}
            alt="Изображение слайдера"
          />
        </div>

        <div className={css.picture_mobile}>
          <Image
            src={slide.imgMobile}
            layout="fill"
            objectFit="cover"
            loading={index === 0 || index === 1 ? 'eager' : 'lazy'}
            priority={index === 0 || index === 1}
            sizes="(max-width:768px) 768px, 10px"
            quality={90}
            alt="Изображение слайдера"
          />
        </div>
      </div>
    )

    const element = slide?.slideLink ? (
      slide?.slideLink?.startsWith('http') ? (
        React.createElement(
          'a',
          { href: slide.slideLink, target: '_blank' },
          content,
        )
      ) : (
        <Link href={slide.slideLink} passHref>
          <a>{content}</a>
        </Link>
      )
    ) : (
      <>{content}</>
    )

    return <>{element}</>
  }
