import React from 'react'
import { GetStaticProps, NextPage } from 'next'
import { initializeApollo } from '../lib/apolloClient'
import globalCache from '../lib/globalCache'
import MainPage from '../components/MainPage/MainPage'
import MainPageProvider from '../contexts/mainPageProvider'
import { main_page, main_pageVariables } from '../__generated__/main_page'
import { MAIN_PAGE_QUERY } from '../queries/mainPageQuery'
import { getMainPageVariables } from '../lib/gql'
import { HeaderColors } from '../components/types'
import { createPageSeo, getPageSeo } from '../lib/createPageSeo'

const Home: NextPage<{ today: string }> = ({ today }) => {
  return (
    <MainPageProvider today={today}>
      <MainPage />
    </MainPageProvider>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo()
  const today = new Date().toISOString()
  await globalCache(apolloClient)
  let seo

  const [seoData] = await Promise.all([
    getPageSeo(apolloClient, 'main-page'),
    apolloClient.query<main_page, main_pageVariables>({
      query: MAIN_PAGE_QUERY,
      variables: getMainPageVariables(today),
    }),
  ])

  if (seoData?.data?.element) {
    seo = createPageSeo(seoData.data)
  }

  return {
    props: {
      __APOLLO_STATE__: apolloClient.cache.extract(),
      headerInitColor: HeaderColors.fixedTransparent,
      seo,
      today,
    },
    revalidate: 60,
  }
}

export default Home
