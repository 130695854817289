import {
  IMainPageFirstTextBlock,
  IMainPagePlate,
  IMainPageTextBlocks,
} from '../components/types'
import { main_page_textElements } from '../__generated__/main_page'
import {
  getPropByCode,
  getPropDescriptionsByCode,
  getPropFileByCode,
  getPropFilesByCode,
  getPropValuesByCode,
} from './gql'

export const createMainPageTextBlocks = (
  elements: main_page_textElements,
): IMainPageTextBlocks => {
  //first block
  const first: IMainPageFirstTextBlock = {
    title: null,
    advantages: null,
    quote: null,
    link: null,
  }

  const firstelem = elements?.nodes?.filter(
    (node) => node.code === 'kosmetika-s-zabotoy-o-kozhe',
  )[0]

  if (firstelem) {
    first.title = firstelem.name

    //advantages

    const featuresProp = firstelem.propertyValues?.filter(
      (val) => val.property?.code === 'FEATURES',
    )[0]
    const featuresImages = getPropFilesByCode(
      firstelem.propertyValuesRelation,
      'FEATURES_FILES',
    )

    first.advantages = featuresProp?.values?.map((val, index) => ({
      id: `advantage-${val}-${featuresProp.id}`,
      code: val,
      description: featuresProp.descriptions[index],
      img: featuresImages[index].url,
    }))

    // quote
    const quotePost = getPropByCode(firstelem.propertyValues, 'QUOTE_POST')
    const quoteName = getPropByCode(firstelem.propertyValues, 'QUOTE_NAME')
    const quoteText = getPropByCode(firstelem.propertyValues, 'QUOTE_TEXT')
    const quoteImg = getPropFileByCode(
      firstelem.propertyValuesRelation,
      'QUOTE_IMG',
    )
    const quoteBugImage = firstelem.detailPicture?.url

    first.quote = {
      name: quoteName,
      post: quotePost,
      text: quoteText,
      img: quoteImg,
      bigImg: quoteBugImage,
    }

    //link
    const linkImg = firstelem.previewPicture?.url
    const link = getPropByCode(firstelem.propertyValues, 'LINK_URL')
    const linkName = getPropByCode(firstelem.propertyValues, 'LINK_TITLE')

    first.link = {
      link,
      title: linkName,
      img: linkImg,
    }
  }

  //plates

  const plates: IMainPagePlate[] = []

  const platesElems = elements?.nodes?.filter((node) =>
    node.code.includes('plitki'),
  )

  platesElems?.length > 0 &&
    platesElems.map((plate) => {
      const vals = getPropValuesByCode(plate.propertyValues, 'FEATURES')
      const descrs = getPropDescriptionsByCode(plate.propertyValues, 'FEATURES')

      const id = plate.id
      const smallText =
        vals[descrs.findIndex((descr) => descr === 'small-text')]
      const bigText = vals[descrs.findIndex((descr) => descr === 'big-text')]
      const linkName = getPropByCode(plate.propertyValues, 'LINK_TITLE')
      const link = getPropByCode(plate.propertyValues, 'LINK_URL')
      const img = plate.previewPicture?.url

      plates.push({
        id,
        smallText,
        bigText,
        link,
        linkName,
        img,
      })
    })

  return { first, plates }
}
