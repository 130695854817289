import { useQuery } from '@apollo/client'
import React, { createContext, FC } from 'react'
import {
  ICatalogItem,
  IMainBeforeAndAfter,
  IMainPageCategory,
  IMainPageTextBlocks,
  IMainSliderSlide,
  IPlayer,
  IVideoSlide,
} from '../components/types'
import { createBeforeAndAfterBlock } from '../lib/createBeforeAndAfterBlock'
import { createCatalogElement } from '../lib/createCatalogItem'
import { createMainCategoryIcons } from '../lib/createMainIcons'
import { createMainPageTextBlocks } from '../lib/createMainPageTextBlocks'
import { createMainSlide } from '../lib/createMainSlider'
import { createMainVideoSlide } from '../lib/createMainVideoSlide'
import { createVideo } from '../lib/createVideo'
import { getMainPageVariables } from '../lib/gql'
import { MAIN_PAGE_QUERY } from '../queries/mainPageQuery'
import { journalItems_elements_edges_node } from '../__generated__/journalItems'
import { main_page, main_pageVariables } from '../__generated__/main_page'

interface IMainPage {
  mainSlider: IMainSliderSlide[]
  video: IPlayer
  journalItems: journalItems_elements_edges_node[]
  benefits: ICatalogItem[]
  category: IMainPageCategory
  videoSlides: IVideoSlide[]
  textBlocks: IMainPageTextBlocks
  beforeAndAfter: IMainBeforeAndAfter
}
export const MainPageContext = createContext({} as IMainPage)

const MainPageProvider: FC<{ today: string }> = ({ children, today }) => {
  const { data } = useQuery<main_page, main_pageVariables>(MAIN_PAGE_QUERY, {
    variables: getMainPageVariables(today),
  })

  const mainSlider = data?.slider?.nodes?.map((node) => createMainSlide(node))
  const video = createVideo(data?.video)
  const journalItems = data?.journal
    ?.nodes as journalItems_elements_edges_node[]
  const benefits = data?.benefits?.edges
    ?.slice(0, 3)
    ?.map((edge) => createCatalogElement(edge.node))

  const category: IMainPageCategory = {
    title: data?.category?.targetSection?.name,
    description: data?.category?.targetSection?.description,
    img: data?.category?.targetSection?.previewPicture?.url,
    elements: data?.category?.targetSection?.childElementsAll?.map((element) =>
      createCatalogElement(element),
    ),
    icons: createMainCategoryIcons(data?.category?.targetSection),
  }

  const videoSlides = data?.videoSlider?.nodes?.map((node) =>
    createMainVideoSlide(node),
  )
  const textBlocks = createMainPageTextBlocks(data?.textElements)
  const beforeAndAfter = createBeforeAndAfterBlock(data?.beforeAfter)

  return (
    <MainPageContext.Provider
      value={{
        mainSlider,
        video,
        journalItems,
        benefits,
        category,
        videoSlides,
        textBlocks,
        beforeAndAfter,
      }}
    >
      {children}
    </MainPageContext.Provider>
  )
}

export default MainPageProvider
