import React, { FC } from 'react'
import Card from '../../catalog/Card/Card'
import MaxWidth from '../../layout/MaxWidth/MaxWidth'
import { IMainPageCategory } from '../../types'
import css from './MainPageCategory.module.scss'
import Image from '../../common/Image/Image'

const MainPageCategory: FC<{ category: IMainPageCategory }> = ({
  category,
}) => {
  if (!category) {
    return null
  }
  return (
    <MaxWidth mobileWide>
      <section className={css.wrapper}>
        <div>
          <h2 className={css.title}>{category.title}</h2>
          {category.description && (
            <p className={css.description}>{category.description}</p>
          )}
        </div>

        {category?.img && (
          <figure className={css.cover}>
            <Image
              src={category.img}
              layout="responsive"
              objectFit="cover"
              width={640}
              height={800}
              quality={80}
              alt="Изображение категории 'Глаза'"
            />
          </figure>
        )}

        {category?.elements?.length > 0 && (
          <ul className={css.list}>
            {category.elements.map((item, index) => (
              <Card
                key={item.id}
                element={item}
                index={index}
                hideSubcategory
                category={item.section}
                lazyImages
                sizes="(max-width: 320px) 280px, (max-width: 400px) 360px, (max-width: 476px) 436px, (max-width: 576px) 536px, (max-width: 767px) 349px, (max-width: 992px) 441px, (max-width: 1080px) 215px, (max-width: 1300px) 244px, (max-width: 1400px) 269px, 310px"
              />
            ))}
          </ul>
        )}

        {category?.icons?.length > 0 && (
          <ul className={css.advantages_list}>
            {category.icons.map((icon) => (
              <li key={icon.id}>
                {icon.img && (
                  <Image
                    src={icon.img}
                    layout="responsive"
                    width={55}
                    height={55}
                  />
                )}
                <p>{icon.descr}</p>
              </li>
            ))}
          </ul>
        )}
      </section>
    </MaxWidth>
  )
}

export default MainPageCategory
