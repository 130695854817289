import { IVideoSlide } from '../components/types'
import { main_page_videoSlider_nodes } from '../__generated__/main_page'
import { getPropFileByCode } from './gql'
import { getImageSrc } from './utils'

export const createMainVideoSlide = (
  element: main_page_videoSlider_nodes,
): IVideoSlide => {
  const video = getPropFileByCode(element?.propertyValuesRelation, 'VIDEO')
  return {
    id: element?.id,
    quote: element?.detailText,
    video: getImageSrc(video),
    author: {
      img: getImageSrc(element?.previewPicture?.url),
      description: element?.previewText,
    },
  }
}
