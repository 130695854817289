import { HeaderTextColors, IMainSliderSlide } from '../components/types'
import { main_page_slider_nodes } from '../__generated__/main_page'
import { getPropByCode, getPropFileByCode } from './gql'
import { getImageSrc } from './utils'

export const createMainSlide = (
  element: main_page_slider_nodes,
): IMainSliderSlide => {
  if (!element) {
    return null
  }

  const title = getPropByCode(element.propertyValues, 'TITLE') || ''
  const code = element.id
  const description = getPropByCode(element.propertyValues, 'DESCRIPTION') || ''
  const buttonText = getPropByCode(element.propertyValues, 'BUTTON_TEXT') || ''
  const buttonLink = getPropByCode(element.propertyValues, 'BUTTON_LINK') || ''
  const buttonColor =
    getPropByCode(element.propertyValuesEnum, 'BUTTON_COLOR') || ''
  const textAlign = getPropByCode(element.propertyValuesEnum, 'ALIGN') || ''
  const textColor =
    getPropByCode(element.propertyValuesEnum, 'TEXT_COLOR') || ''
  const bgColor = getPropByCode(element.propertyValuesEnum, 'BG_COLOR') || ''
  const img = getPropFileByCode(element.propertyValuesRelation, 'IMAGE_DESKTOP')
  const imgMobile = getPropFileByCode(
    element.propertyValuesRelation,
    'IMAGE_MOBILE',
  )
  const slideLink = getPropByCode(element.propertyValues, 'SLIDE_LINK') || ''

  //header color

  let headerColor

  switch (bgColor) {
    case 'Светлый':
      headerColor = HeaderTextColors.white
      break
    default:
      headerColor = HeaderTextColors.init
      break
  }

  //text align

  let align = 'text-align-'

  switch (textAlign) {
    case 'Справа':
      align += 'right'
      break
    case 'Центр':
      align += 'center'
      break
    case 'Без текста':
      align += 'none'
      break
    case 'Слева':
    default:
      align += 'left'
      break
  }

  //text color

  let tColor = 'text-color-'

  switch (textColor) {
    case 'Светлый':
      tColor += 'light'
      break
    case 'Темный':
    default:
      tColor += 'dark'
      break
  }

  //button color

  let color = 'button-'

  switch (buttonColor) {
    case 'Черный':
      color += 'black'
      break
    case 'Белый':
      color += 'white'
      break
    case 'Коричневый':
    default:
      color += 'brown'
      break
  }

  return {
    code,
    title,
    img: getImageSrc(img),
    imgMobile: getImageSrc(imgMobile),
    description,
    buttonText,
    buttonLink,
    buttonColor: color,
    textAlign: align,
    textColor: tColor,
    headerColor,
    slideLink,
  }
}
