import React, { FC } from 'react'
import Card from '../../catalog/Card/Card'
import Button from '../../common/Button/Button'
import MaxWidth from '../../layout/MaxWidth/MaxWidth'
import { ButtonTheme, ICatalogItem } from '../../types'
import css from './MainPageBenefits.module.scss'
import cx from 'classnames'

const MainPageBenefits: FC<{ items: ICatalogItem[] }> = ({ items }) => {
  return (
    <section className={css.wrapper}>
      <MaxWidth>
        <div className={css.top}>
          <h2 className={cx(css.heading, 'apoc')}>Выгодные предложения</h2>
          <span>В&nbsp;наборе дешевле</span>
        </div>
        <ul className={css.list}>
          {items.map((item, index) => (
            <Card
              key={item.id}
              element={item}
              index={index}
              category={item.section}
              sizes="(max-width: 320px) 280px, (max-width: 400px) 360px, (max-width: 480px) 440px, (max-width: 576px) 536px, (max-width: 768px) 728px, (max-width: 992px) 439px, (max-width: 1080px) 290px, (max-width: 1300px) 328px, (max-width: 1400px) 361px, 416px"
            />
          ))}
        </ul>

        <Button
          className={css.button}
          href="/catalog/nabory"
          theme={ButtonTheme.ellipse}
        >
          все товары
        </Button>
      </MaxWidth>
    </section>
  )
}

export default MainPageBenefits
