import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Pagination,
  Navigation,
  Autoplay,
  SwiperOptions,
} from 'swiper'
import { HeaderTextColors, IMainSliderSlide } from '../../types'
import Arrow from '../../../public/static/icons/arrow-left.svg'

import css from './MainSlider.module.scss'
import cx from 'classnames'
import { LayoutContext } from '../../../contexts/layoutProvider'
import { MainSliderSlide } from './MainSliderSLide'

SwiperCore.use([Pagination, Navigation, Autoplay])

const MainSlider: FC<{ slider: IMainSliderSlide[] }> = ({ slider }) => {
  const [activeIndex, setActtiveIndex] = useState(0)
  const [swiper, setSwiper] = useState(null)
  const {
    setHeaderTextColor,
    secondNavOpen,
    mobileNavOpen,
    searchOpen,
    fixed,
  } = useContext(LayoutContext)

  const pag = useRef(null)
  if (slider?.length === 0) {
    return null
  }
  const interleaveOffset = 0.5

  const options: SwiperOptions = {
    loop: true,
    watchSlidesProgress: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      clickable: true,
      bulletClass: css.dot,
      bulletActiveClass: css.dot_active,
      el: pag.current,
      bulletElement: 'div',
    },
  }

  useEffect(() => {
    return () => setHeaderTextColor(HeaderTextColors.init)
  }, [])

  return (
    <div className={css.wrapper}>
      <Swiper
        {...options}
        className={css.slider}
        onInit={(swiper) => setSwiper(swiper)}
        onSlideChange={(swiper) => setActtiveIndex(swiper.realIndex)}
        onProgress={() => {
          for (let i = 0; i < swiper?.slides.length; i++) {
            const slideProgress = swiper?.slides[i].progress
            const innerOffset = swiper?.width * interleaveOffset
            const innerTranslate = slideProgress * innerOffset
            swiper.slides[i].querySelector('.slide-inner').style.transform =
              'translate3d(' + innerTranslate + 'px, 0, 0)'
          }
        }}
        onTouchStart={() => {
          for (let i = 0; i < swiper?.slides.length; i++) {
            swiper.slides[i].style.transition = ''
          }
        }}
        onSetTransition={(_, transition) => {
          for (let i = 0; i < swiper?.slides.length; i++) {
            swiper.slides[i].style.transition = transition + 'ms'
            swiper.slides[i].querySelector('.slide-inner').style.transition =
              transition + 'ms'
          }
        }}
      >
        {slider.map((slide, index) => {
          if (
            slider[activeIndex].headerColor === HeaderTextColors.white &&
            !searchOpen &&
            !mobileNavOpen &&
            !fixed &&
            !secondNavOpen
          ) {
            setHeaderTextColor(HeaderTextColors.white)
          } else {
            setHeaderTextColor(HeaderTextColors.init)
          }
          return (
            <SwiperSlide
              key={slide.code}
              className={cx(css.slide, activeIndex === index && css.active)}
            >
              <MainSliderSlide slide={slide} index={index} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <button
        onClick={() => swiper?.slideNext()}
        type="button"
        className={cx(css.btn, css.btn_next)}
        aria-label="Переключить на следующий слайд"
      >
        <Arrow viewBox="0 0 16 16" />
      </button>
      <div className={css.pagination} ref={pag} />
    </div>
  )
}

export default MainSlider
