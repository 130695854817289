import { IMainBeforeAndAfter } from '../components/types'
import { main_page_beforeAfter } from '../__generated__/main_page'
import { getPropFilesByCode, getPropValuesByCode } from './gql'
import { getOptimizedImageSrc } from './utils'

export const createBeforeAndAfterBlock = (
  elements: main_page_beforeAfter[],
): IMainBeforeAndAfter => {
  if (!elements) {
    return null
  }
  //section
  const section = elements.filter(
    (el) => el.code === 'UF_MAIN_PAGE_BEFORE_AFTER_SECTION',
  )[0]?.targetSection
  const title = section.name
  const description = section.description
  const link = `/catalog/${
    section.parentSection
      ? section.parentSection.code + '/' + section.code
      : section.code
  }`
  const linkImg = getOptimizedImageSrc(section.previewPicture?.url)

  //before
  const beforeEl = elements.filter((el) => el.code === 'UF_MAIN_BEFORE')[0]
  const before = getOptimizedImageSrc(beforeEl?.targetFile?.url)

  //after
  const afterEl = elements.filter((el) => el.code === 'UF_MAIN_AFTER')[0]
  const after = getOptimizedImageSrc(afterEl?.targetFile?.url)

  //icons
  const iconsDescriptions = getPropValuesByCode(
    section.propertyValues,
    'UF_ICONS_TEXT',
  )
  const iconsImgs = getPropFilesByCode(
    section.propertyValuesRelation,
    'UF_ICONS_FILES',
  )

  const icons = iconsDescriptions?.map((descr, index) => ({
    id: `${section.id}_${iconsImgs[index]?.id}_${index}`,
    descr,
    img: iconsImgs[index]?.url,
  }))

  return { title, description, link, linkImg, before, after, icons }
}
