import React, { FC, useContext } from 'react'
import MaxWidth from '../../layout/MaxWidth/MaxWidth'
import css from './FirstBlock.module.scss'
import Link from 'next/link'
import { MainPageContext } from '../../../contexts/mainPageProvider'
import Image from '../../common/Image/Image'

const FirstBlock: FC = () => {
  const { textBlocks } = useContext(MainPageContext)

  if (!textBlocks?.first) {
    return null
  }

  const sizes =
    '(max-width: 320px) 320px, (max-width: 400px) 400px, (max-width: 480px) 480px, (max-width: 576px) 576px, (max-width: 768px) 768px, (max-width: 992px) 440px, (max-width: 1080px) 460px, (max-width: 1300px) 520px, (max-width: 1400px) 568px, 650px'

  return (
    <MaxWidth mobileWide>
      <section className={css.wrapper}>
        <div className={css.top}>
          <h1 className={css.title}>{textBlocks.first.title}</h1>
          {textBlocks.first.advantages?.length > 0 && (
            <ul className={css.advantage_list}>
              {textBlocks.first.advantages.map((item) => (
                <li key={item.id}>
                  <Image
                    src={item.img}
                    layout="responsive"
                    width={55}
                    height={55}
                    alt="Иконка"
                  />
                  <p>{item.description}</p>
                </li>
              ))}
            </ul>
          )}
        </div>

        {textBlocks.first.link && (
          <Link href={textBlocks.first.link?.link} passHref>
            <a className={css.catalog_link}>
              <figure>
                <Image
                  src={textBlocks.first.link?.img}
                  layout="responsive"
                  width={650}
                  height={702}
                  objectFit="cover"
                  quality={90}
                  priority
                  sizes={sizes}
                  alt="Изображение"
                />
                <figcaption>{textBlocks.first.link?.title}</figcaption>
              </figure>
            </a>
          </Link>
        )}

        {textBlocks.first.quote?.bigImg && (
          <figure className={css.cover}>
            <Image
              src={textBlocks.first.quote?.bigImg}
              alt="Изображение"
              layout="responsive"
              width={650}
              height={874}
              objectFit="cover"
              quality={90}
              loading="eager"
              sizes={sizes}
            />
          </figure>
        )}

        <div className={css.quote_block}>
          <blockquote className={css.quote}>
            {textBlocks.first.quote?.text}
          </blockquote>
          <cite className={css.quote_description}>
            {textBlocks.first.quote?.img && (
              <figure>
                <Image
                  src={textBlocks.first.quote?.img}
                  layout="fill"
                  alt={textBlocks.first.quote?.name}
                />
              </figure>
            )}
            <div>
              <p>{textBlocks.first.quote?.name}</p>
              <p>{textBlocks.first.quote?.post}</p>
            </div>
          </cite>
        </div>
      </section>
    </MaxWidth>
  )
}

export default FirstBlock
