import gql from 'graphql-tag'
import { ELEMENT_EDGES, ELEMENT_FIELDS } from './catalogQuery'
import { JOURNAL_ITEM_FEILDS } from './journalQuery'
import { VIDEO_FILEDS } from './videoFragment'

export const MAIN_PAGE_SLIDER_FIELDS = gql`
  fragment main_page_slider_fields on Element {
    id
    code
    name
    propertyValues {
      property {
        code
      }
      value
    }
    propertyValuesEnum {
      property {
        code
      }
      value
    }
    propertyValuesRelation {
      property {
        code
      }
      targetFile {
        url
      }
    }
  }
`

export const MAIN_PAGE_VIDEO_SLIDER_FIELDS = gql`
  fragment main_page_video_slider_fields on Element {
    id
    code
    previewText
    previewPicture {
      url
    }
    detailText
    propertyValuesRelation {
      property {
        name
        code
      }
      targetFile {
        id
        url
      }
    }
  }
`

export const MAIN_PAGE_QUERY = gql`
  query main_page(
    $sliderWhere: ElementWhereInput
    $textElementsWhere: ElementWhereInput
    $videoWhere: SettingPlusWhereUniqueInput!
    $BAWhere: SettingPlusWhereInput
    $journalWhere: ElementWhereInput
    $benefitsWhere: ElementWhereInput
    $categoryWhere: SettingPlusWhereUniqueInput!
    $categoryElementsWhere: ElementWhereInput
    $videoSliderWhere: ElementWhereInput
    $videoSliderOrder: ElementOrderByInput
    $categoryFirst: Int
    $videoSliderFirst: Int
    $mainSliderFirst: Int
    $first: Int
  ) {
    slider: elements(
      where: $sliderWhere
      first: $mainSliderFirst
      order: { sort: asc }
    ) {
      nodes {
        ...main_page_slider_fields
      }
    }
    textElements: elements(
      where: $textElementsWhere
      first: 999
      order: { sort: asc }
    ) {
      nodes {
        id
        name
        code

        detailPicture {
          id
          url
        }
        previewPicture {
          id
          url
        }

        propertyValues {
          id
          property {
            id
            code
            name
          }

          value
          values
          description
          descriptions
        }

        propertyValuesRelation {
          id
          property {
            id
            code
            name
          }

          targetFile {
            id
            url
          }
          targetFiles {
            id
            url
          }
        }
      }
    }
    video: settingPlus(where: $videoWhere) {
      ...video_fields
    }
    beforeAfter: settingPluses(where: $BAWhere) {
      id
      code
      targetFile {
        id
        url
      }

      targetSection {
        id
        code
        name
        description
        previewPicture {
          id
          url
        }
        propertyValues {
          property {
            id
            code
          }
          id
          values
        }

        propertyValuesRelation {
          property {
            id
            code
          }
          id
          targetFiles {
            id
            url
          }
        }

        parentSection {
          id
          code
        }
      }
    }
    journal: elements(
      where: $journalWhere
      first: $first
      order: { activeFrom: desc }
    ) {
      nodes {
        ...journalItemFields
      }
    }
    category: settingPlus(where: $categoryWhere) {
      id
      targetSection {
        id
        name
        description
        previewPicture {
          id
          url
        }

        propertyValues {
          property {
            id
            code
          }
          id
          values
        }

        propertyValuesRelation {
          property {
            id
            code
          }
          id
          targetFiles {
            id
            url
          }
        }

        childElementsAll(
          where: $categoryElementsWhere
          first: $categoryFirst
          orderBy: { createdAt: desc }
        ) {
          ...elementFields
          priceBase
          priceBaseWithDiscount

          sku(where: { active: { equals: true } }) {
            id
            name
            priceBase
            priceBaseWithDiscount
          }
        }
      }
    }
    benefits: elements(
      where: $benefitsWhere
      first: $first
      order: { createdAt: desc }
    ) {
      edges {
        ...elementEdges
      }
    }
    videoSlider: elements(
      where: $videoSliderWhere
      first: $videoSliderFirst
      order: $videoSliderOrder
    ) {
      nodes {
        ...main_page_video_slider_fields
      }
    }
  }
  ${MAIN_PAGE_SLIDER_FIELDS}
  ${MAIN_PAGE_VIDEO_SLIDER_FIELDS}
  ${JOURNAL_ITEM_FEILDS}
  ${ELEMENT_EDGES}
  ${VIDEO_FILEDS}
  ${ELEMENT_FIELDS}
`
