import { IMainIcons } from '../components/types'
import { main_page_category_targetSection } from '../__generated__/main_page'
import { getPropFilesByCode, getPropValuesByCode } from './gql'

export const createMainCategoryIcons = (
  section: main_page_category_targetSection,
): IMainIcons[] => {
  if (!section) {
    return null
  }
  const icons = []

  const vals = getPropValuesByCode(section?.propertyValues, 'UF_ICONS_TEXT')
  const iconsFiles = getPropFilesByCode(
    section?.propertyValuesRelation,
    'UF_ICONS_FILES',
  )

  vals?.map((val, index) => {
    icons.push({
      id: `${section?.id}_${iconsFiles[index]?.id}_${index}`,
      descr: val,
      img: iconsFiles[index]?.url,
    })
  })

  return icons
}
