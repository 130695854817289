import React, { FC, useContext } from 'react'
import { MainPageContext } from '../../contexts/mainPageProvider'
import MaxWidth from '../layout/MaxWidth/MaxWidth'
import MoreBlock from '../common/MoreBlock/MoreBlock'
import MainJournalBlock from './MainJournalBlock/MainJournalBlock'
import MainSlider from './MainSlider/MainSlider'
import css from './MainPage.module.scss'
import MainPageBenefits from './MainPageBenefits/MainPageBenefits'
import MainPageCategory from './MainPageCategory/MainPageCategory'
import MainBeforeAndAfter from './MainBeforeAndAfter/MainBeforeAndAfter'
import FirstBlock from './FirstBlock/FirstBlock'
import VideoSlider from './VideoSlider/VideoSlider'
import Player from '../common/Player/Player'
import SparkleSvg from '../../public/static/icons/sparcle.svg'
import Image from '../common/Image/Image'
import Button from '../common/Button/Button'
import { ButtonTheme } from '../types'

const MainPage: FC = () => {
  const {
    mainSlider,
    video,
    journalItems,
    benefits,
    category,
    videoSlides,
    textBlocks,
  } = useContext(MainPageContext)
  return (
    <div>
      {mainSlider?.length > 0 && <MainSlider slider={mainSlider} />}

      <FirstBlock />

      {video && (
        <MaxWidth className={css.video_wrapper} mobileWide>
          <Player {...video} />
        </MaxWidth>
      )}

      <MainBeforeAndAfter />

      {benefits?.length > 0 && <MainPageBenefits items={benefits} />}

      {category && <MainPageCategory category={category} />}

      {videoSlides?.length > 0 && <VideoSlider items={videoSlides} />}

      {textBlocks?.plates?.length > 0 && (
        <MaxWidth mobileWide>
          <section className={css.plates}>
            {textBlocks.plates.map((plate) => (
              <div key={plate.id}>
                <div>
                  <SparkleSvg />

                  {plate.smallText && <h3>{plate.smallText}</h3>}
                  {plate.bigText && <p>{plate.bigText}</p>}

                  {plate.link && plate.linkName && (
                    <Button
                      href={plate.link}
                      theme={ButtonTheme.ellipse}
                      className={css.button}
                    >
                      {plate.linkName}
                    </Button>
                  )}
                </div>

                {plate.img && (
                  <>
                    <figure className={css.image}>
                      <Image
                        src={plate.img}
                        layout="fill"
                        objectFit="cover"
                        quality={80}
                        alt="Изображение"
                      />
                    </figure>
                    <figure className={css.mobile_image}>
                      <Image
                        src={plate.img}
                        layout="responsive"
                        width={380}
                        height={260}
                        objectFit="cover"
                        quality={80}
                        alt="Изображение"
                      />
                    </figure>
                  </>
                )}
              </div>
            ))}
          </section>
        </MaxWidth>
      )}

      {journalItems?.length > 0 && <MainJournalBlock items={journalItems} />}

      <section className={css.more}>
        <MaxWidth>
          <MoreBlock />
        </MaxWidth>
      </section>
    </div>
  )
}

export default MainPage
