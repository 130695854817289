import React, { FC, useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Pagination,
  EffectFade,
  Autoplay,
  SwiperOptions,
} from 'swiper'
import MaxWidth from '../../layout/MaxWidth/MaxWidth'
import { IVideoSlide } from '../../types'
import css from './VideoSlider.module.scss'
import Plyr from 'plyr'
import MutedIcon from '../../../public/static/icons/muted.svg'
import UnmutedIcon from '../../../public/static/icons/unmuted.svg'
import { useInView } from 'react-intersection-observer'
import { clearHtml } from '../../../lib/utils'
import Image from '../../common/Image/Image'
import cx from 'classnames'
import ArrowIcon from '../../../public/static/icons/arrow-left.svg'

SwiperCore.use([EffectFade, Pagination, Autoplay])

const VideoSlider: FC<{ items: IVideoSlide[] }> = ({ items }) => {
  const [swiper, setSwiper] = useState<SwiperCore>(null)
  const [activeVideo, setActiveVideo] = useState(items[0].video)
  const [playerInst, setPlayerInst] = useState(null)
  const [muted, setMuted] = useState(true)
  const [duration, setDuration] = useState(0)
  const { ref, inView } = useInView()

  const pag = useRef(null)
  const options: SwiperOptions = {
    effect: 'fade',
    autoHeight: true,
    loop: true,
    pagination: {
      bulletClass: css.pag_line,
      bulletActiveClass: css.active,
      el: pag.current,
      bulletElement: 'div',
      clickable: true,
    },
  }

  const videoRef = useRef<HTMLVideoElement>(null)

  const plyrOptions = {
    controls: [],
    fullscreen: { enabled: false },
    muted,
    clickToPlay: false,
  }

  useEffect(() => {
    if (!swiper) {
      return
    }

    swiper.pagination.bullets.forEach((item, _, arr) => {
      const element = item as unknown as HTMLElement
      element.style.width = `calc(100% / ${arr.length} - 5px)`
    })
  }, [swiper])

  useEffect(() => {
    setPlayerInst(new Plyr(videoRef.current, plyrOptions))

    if (playerInst && inView) {
      setDuration(playerInst.duration)

      playerInst.on('canplay', () => {
        setDuration(playerInst.duration)
      })

      playerInst.play()
    }
  }, [videoRef, activeVideo])

  useEffect(() => {
    if (playerInst) {
      playerInst.muted = muted
    }
  }, [muted, playerInst])

  useEffect(() => {
    if (inView && !playerInst?.ended) {
      setDuration(playerInst?.duration)

      const playPromise = playerInst?.play()
      if (playPromise && inView) {
        playPromise.catch(() => playerInst?.pause())
      }
    }
  }, [inView])

  const style = {
    '--transition-duration': `${duration}s`,
  } as React.CSSProperties

  return (
    <MaxWidth mobileWide>
      <section className={css.wrapper} ref={ref}>
        <div className={css.video_wrapper}>
          <button
            className={css.video_mute}
            onClick={() => setMuted((prev) => !prev)}
            aria-label={muted ? 'Включить звук' : 'Выключить звук'}
          >
            {muted ? <MutedIcon /> : <UnmutedIcon />}
          </button>
          <video
            className={css.video}
            ref={videoRef}
            src={activeVideo}
            playsInline
            onEnded={() => {
              swiper?.slideNext()
            }}
          />
        </div>

        <div className={css.sliderWrapper}>
          <div>
            <h2 className={css.title}>Нам не&nbsp;все равно!</h2>
            <p className={css.description}>
              Мы&nbsp;постоянно ведем опросы. Они помогут вам понять
              почему&nbsp;98% клиентов довольны покупкой!
            </p>
          </div>

          <div>
            <Swiper
              {...options}
              className={css.slider}
              onInit={(swiper) => setSwiper(swiper)}
              onSlideChange={(swiper) =>
                setActiveVideo(items[swiper.realIndex].video)
              }
            >
              {items?.map((video) => (
                <SwiperSlide key={video.id} className={css.slide}>
                  <blockquote>{video.quote}</blockquote>
                  <cite>
                    <figure>
                      <Image
                        src={video.author.img}
                        layout="fixed"
                        width={60}
                        height={60}
                        loading="lazy"
                        alt={video.author.description}
                      />
                    </figure>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: clearHtml(video.author.description),
                      }}
                    />
                  </cite>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className={css.controlsWrapper}>
              <div
                className={cx(css.pag, duration && css.played)}
                ref={pag}
                style={style}
              />
              <button
                type="button"
                title="Следующий слайд"
                className={css.next}
                onClick={() => {
                  if (swiper?.activeIndex === items.length - 1) {
                    swiper.slideTo(0)
                  } else {
                    swiper?.slideNext()
                  }
                }}
              >
                <ArrowIcon />
              </button>
            </div>
          </div>
        </div>
      </section>
    </MaxWidth>
  )
}

export default VideoSlider
