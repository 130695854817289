import React, { FC, useContext } from 'react'

import SparcleIcon from '../../../public/static/icons/sparcle.svg'

import Button from '../Button/Button'
import css from './MoreBlock.module.scss'
import { ButtonTheme } from '../../types'
import Image from '../Image/Image'

import RoundBorderSvg from '../../../public/static/icons/round-border.svg'
import { LayoutContext } from '../../../contexts/layoutProvider'
import { SOCNETS } from '../../data'

const MoreBlock: FC = () => {
  const { more } = useContext(LayoutContext)

  return (
    <section className={css.wrapper}>
      {more?.img && (
        <figure className={css.image}>
          <Image
            src={more.img}
            alt="more"
            layout="responsive"
            width={700}
            height={630}
            quality={90}
          />
        </figure>
      )}

      <div className={css.more}>
        <div className={css.sparcle}>
          <SparcleIcon />
        </div>
        {more?.text && <p className={css.heading}>{more?.text}</p>}

        {more?.socNets?.length > 0 && (
          <ul className={css.socials}>
            {more?.socNets?.map((soc) => {
              if (!Object.keys(SOCNETS).includes(soc.code)) {
                return null
              }

              return (
                <li key={`more_${soc.id}`}>
                  <a
                    href={soc.link}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={`Ссылка на ${soc.code}`}
                  >
                    <RoundBorderSvg className={css.border} />
                    {SOCNETS[soc.code]}
                  </a>
                </li>
              )
            })}
          </ul>
        )}

        {more?.link && more?.linkName && (
          <Button
            href={more?.link}
            target="_blank"
            className={css.button}
            theme={ButtonTheme.ellipseMedium}
          >
            {more?.linkName}
          </Button>
        )}
      </div>
    </section>
  )
}

export default MoreBlock
